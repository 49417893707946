.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 2rem;
	padding-bottom: 6rem;
	width: 100%;
	background-color: #180652;
    font-family: "Noto Sans KR", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
}

.logo {
	width: 20rem;

	@media (max-width: 980px) {
		width: 12rem;
	}
}


.menu {
	margin: 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	color: #FFFFFF;

	.link {
		margin: 0.5rem 1rem;
		color: inherit;
		text-decoration: none;
	}

	:hover {
		font-weight: 400;
		border-bottom: 0.5px solid;
	}
}

.text {
	margin: 0.5rem 0;
	font-size: 0.8rem;
	color: #FFFFFF;

	@media (max-width: 980px) {
		margin: 0.2rem 1rem;
		font-size: 0.5rem;
		text-align: center;
		background-color: #180652;
	}
}

// 모바일

.logoBox {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin: 0;

	.text2 {
		color: #FFFFFF;
		font-size: 1.2rem;
		font-weight: 400;
		text-align: center;
		background-color: #180652;
	}
}