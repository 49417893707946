.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5.4rem;

    @media (max-width: 825px) {
        padding-top: 4rem;
    }
}

.bookingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    gap: 2rem;
    margin: 1.5rem 0 2rem 0;
    width: 100vw;

    @media (max-width: 825px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin: 1.5rem 0 2rem 0;
    }
}

.bookingCase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
        margin: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
    }
}

.bookingBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    select {
        width: 12rem;
        height: 2.35rem;
        font-size: 1rem;
    }

    .input {
        width: 12rem;
        height: 2rem;
        font-size: 1rem;
        text-align: center;

        @media (max-width: 650px) {
            width: 8rem;
            height: 1.5rem;
            font-size: 0.8rem;
        }
    }
}

.mainImage {
    width: 100vw;
}

.bookingButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    color: inherit;
    text-decoration: none;
    background-color: #284F4E;
    border-radius: 0.2rem;

    div {
        font-size: 1rem;
        color: #FFFFFF;
    }
}

.button {
    width: 10rem;
    height: 2.35rem;
    color: #FFFFFF;
    border-radius: 0.2rem;
    background-color: #284F4E;
    border: none;
    font-size: 0.8rem;
    font-weight: bold;
}

.disabledButton {
    width: 10rem;
    height: 2.35rem;
    border-radius: 0.2rem;
    color: #FFFFFF;
    background-color: #284F4E;
    font-size: 0.8rem;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65vw;

    img {
        width: 100%;
    }

    @media (max-width: 650px) {
        width: 100vw;
    }
}